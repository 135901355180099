.map-options {
  // margin-top: -70px;
  text-align: center;
  // display: flex;
  width: 100%;
  font-size: 16px;
  > div {
    margin : 0 20px 0 20px;
  }
}

.analytics-result {
  .tile {
    margin: 10px;
    padding: 10px;
    text-align: center;
    border-radius: 30px;
    box-shadow: 2px 2px 2px #ccc;
    border: thin solid #ccc;
    width: 150px;
    height: 110px;
    .tile-heading {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .tile-data {
      margin-top: 0px;
      text-transform: capitalize;
    }
  }
}
