$scroll-reveal--transition-duration: .6s;
$scroll-reveal--transition-timing: ease;
$scroll-reveal--from-top-amount: -20px;
$scroll-reveal--from-bottom-amount: 20px;
$scroll-reveal--from-left-amount: -20px;
$scroll-reveal--from-right-amount: 20px;
$scroll-reveal--scale-up-amount: 0.95;
$scroll-reveal--scale-down-amount: 1.05;
$scroll-reveal--rotate-from-left-amount: -45deg;
$scroll-reveal--rotate-from-right-amount: 45deg;
