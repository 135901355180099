.split-wrap {

	.split-item-image {

		img,
		svg,
		video {
			border-radius: 3px;
			box-shadow: 0 48px 88px rgba(get-color(dark, 1), .32);
		}

		&.is-3d {

			img,
			svg,
			video {
				will-change: transform;
				transition: all .45s cubic-bezier(0.215, 0.61, 0.355, 1);

				&:hover {
					box-shadow: 0 48px 88px rgba(get-color(dark, 1), .32) !important;
					transform: perspective(1000px) rotateY(0deg) rotateX(0deg) rotateZ(0deg) scale(1) translateX(0) translateY(0) !important;
				}
			}
		}
	}

	&:not(.invert-desktop) .split-item:nth-child(even),
	&.invert-desktop .split-item:nth-child(odd) {

		.split-item-image {

			&.is-3d {

				img,
				svg,
				video {
					box-shadow: -24px 48px 88px rgba(get-color(dark, 1), .32);
					transform: perspective(1000px) rotateY(13deg) rotateX(5deg) rotateZ(-7deg) scaleY(0.9) scaleX(0.95) translateX(3%) translateY(-3%);
				}
			}
		}
	}

	&:not(.invert-desktop) .split-item:nth-child(odd),
	&.invert-desktop .split-item:nth-child(even) {

		.split-item-image {

			&.is-3d {

				img,
				svg,
				video {
					box-shadow: 24px 48px 88px rgba(get-color(dark, 1), .32);
					transform: perspective(1000px) rotateY(-13deg) rotateX(5deg) rotateZ(7deg) scaleY(0.9) scaleX(0.95) translateX(-3%) translateY(-3%);
				}
			}
		}
	}
}
